.flight_details_banner_container {
    height: max-content;
    width: 100%;
    background: #eeeeea;
}

.flight_details_banner_header {
    width: 100%;
    background-color: #868F9C;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    font-size: 18px;
    font-weight: 500;
}

.flight_details_banner_header_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    line-height: 30px;
}

.flight_details_banner_header_left {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    /* width: 60%; */
}

.flight_id {
    font-size: 14px;
    font-weight: 400;
}

.flight_details_banner_data {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    justify-content: space-between;
    width: 100%;
    /* padding: 20px 30px; */
    text-align: center;
    row-gap: 25px;
    column-gap: 10px;
}

.flight_title {
    font-size: 20px;
}

.grid_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.grid_title {
    color: #68717E;
    font-size: 18px;
    font-weight: 400;
}

.grid_sub_title {
    font-size: 16px;
    color: red;
}

.grid_data {
    color: #081425;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    word-wrap: break-word;
    text-align: left;
}

.cards_tray {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: space-between;
    width: 100%;
    text-align: center;
    row-gap: 25px;
    column-gap: 10px;
    padding-top: 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    background: #eeeeea;
}

.media_title {
    position: absolute;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.6867121848739496) 100%);
    color: white;
    width: 100%;
    height: 60px;
    padding-left: 15px;
    z-index: 9;
    padding-top: 10px;
    font-weight: 500;
}

.container_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f6f8;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    padding-left: 15px;
    padding-right: 15px;
}

.flight-detail-chart-title {
    font-size: 20px;
}

.flight_details_banner_info {
    /* padding-left: 80px; */
}

@media screen and (max-width:1919px) {
    .flight_details_banner_info {
        /* padding-left: 50px; */
    }

    .flight_title {
        font-size: 16px;
    }

    .grid_title {
        font-size: 12px;
    }

    .grid_sub_title {
        font-size: 11px;
        color: red;
    }

    .grid_data {
        font-size: 14px;
    }

    .flight-detail-chart-title {
        font-size: 15px;
    }

    .flight_details_banner_data {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        justify-content: space-between;
        width: 100%;
        text-align: center;
        row-gap: 25px;
        column-gap: 10px;
    }
}